<template>
  <column>
    <block>
        <div class="gallery" v-if="project">
            <div class="project__info">
                <router-link :to="{ name: 'home'}">
                    Back to Project View
                </router-link>
                <div class="project__info__header">
                    <div class="title">
                        <h1>{{ project.title }}</h1>
                        <div class="author">
                            <p>by {{ project.author }}</p>
                            <div class="social-network">
                                <img 
                                    src="@/assets/icons/logo-twitter-blue.svg" 
                                    alt="twitter" 
                                />
                                <a 
                                    :href="author.website"
                                    v-for="(author, index) in projectAuthors"
                                    :key="index"
                                >
                                    {{ author.username }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <tab-navigation
                        class="navigation__desktop"
                        :tabs="tabs"
                        @click="selectTab"
                    >
                    </tab-navigation>
                </div>
                <div 
                    v-if="tabSelected == 'editions'"
                    class="project__info__actions" 
                >
                    <div class="section__title">
                        <h3>Token Gallery</h3>
                    </div>
                    <div class="filters">
                        <div class="sort">
                            <div class="sort_by">
                                <div class="sort__dropdown">
                                    <span>Sort By: {{sortFilterLabel}} </span>
                                    <button @click="showHideSortDropdown">
                                        <img 
                                            src="@/assets/icons/chevron-down.svg" 
                                            alt="chevron down"
                                        >
                                    </button>
                                </div>
                                <div 
                                    class="sort__dropdown__options" 
                                    v-show="isSortDropdownOpen"
                                >
                                    <span 
                                        @click="sortArtsFirstToLast()"
                                        :class="{selected: isOrderedFirsToLast}"
                                    > 
                                        token # (first to last) 
                                    </span>
                                    <span 
                                        @click="sortArtsLastToFirst()"
                                        :class="{selected: isOrderedLastToFirst}"
                                    > 
                                        token # (last to first) 
                                    </span>
                                </div>
                            </div>
                            
                            <input
                                v-if="!noTokens"
                                type="text"
                                placeholder="Search by ID"
                                v-model.trim="searchTerm"
                                @keydown="searchArt"
                            />
                        </div>
                    </div>
                </div>
            </div>

             <tab-navigation
                :tabs="tabs"
                @click="selectTab"
                class="tab__desktop"
            >
            </tab-navigation>

            <div class="data">
                <div class="editions" v-if="tabSelected == 'editions'">
                    <div v-if="noTokens">
                        <span>Nothing Minted Yet</span>
                    </div>

                    <div v-if="artsIsLoading" class="loading-wrapper">
                        <loading position="top"> </loading>
                    </div>
                    
                    <div v-else class="arts"> 
                        <div class="card" v-for="art in artList.content" :key="'art-'+art.id">
                            <div class="card-image">
                                <router-link :to="{ path: `/projects/${art.projectId}/art/${art.id}` }">
                                    <img v-if="art.status == 'GENERATED'" :src="art.thumbnailImageUrl" :alt="'image #'">
                                    <img
                                        v-else-if="art.status == 'RESERVED'"
                                        :src="`${imagesUrl}/projects/${art.projectId}/placeholder_ungenerated.png`"
                                        alt="Placeholder"
                                    />
                                </router-link>
                            </div>
                            <div class="card-footer">
                                <span> {{ project.title }} #{{ art.id % 1000000 }}</span>
                                <div class="text">
                                    <router-link
                                        v-if="art.status == 'GENERATED'" 
                                        :to="{
                                        name: 'nft-details',
                                        params: { projectId: projectId, artId: art.id },
                                        }"
                                    >token details
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pagination" v-if="artList.content.length > 0">
                        <div class="pagination-info">
                            <span> 
                                Showing {{firstPageElement}} to 
                                {{lastPageElement}} of 
                                {{artList.totalElements}} tokens
                            </span>
                        </div>
                    
                        <div class="pagination-actions">
                            <button
                                class="primary" 
                                @click="previousPage"
                                :disabled="artList.first"
                            ><img src="@/assets/icons/chevron-left.svg" alt="chevron left">
                            </button>
                            <button
                                class="page"
                                v-for="page in pages.slice(currentPage, artList.totalPages)" :key="page"
                                @click="getArts(page)"
                                :class="{active: currentPage === page}" 
                            >
                                {{ page + 1 }}
                            </button>
                            <button 
                                class="primary" 
                                :disabled="artList.last"
                                @click="nextPage"
                            ><img src="@/assets/icons/chevron-right.svg" alt="chevron right">
                            </button>
                        </div>
                    </div>
                </div>

                <div class="studies" v-else-if="tabSelected == 'studies'">
                    <div class="studies__grid">
                        <thumbnail-study
                            v-for="study in studiesList"
                            :key="'study-'+ study.id"
                            :title="study.alias | collectionName"
                            art_number=""
                            :image_url="study.previewImageUrl"
                            :detail_id="study.id"
                            :project_id="study.project.id"
                        >
                        </thumbnail-study>
                        <p v-if="studiesList.length === 0">
                            There are no studies yet
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </block>
  </column>
</template>

<script>
import Block from "@lkmx/flare/src/core/Block.vue";
import api from "@/service/api";
import Loading from "@/components/Loading";
import ThumbnailTest from '@/components/ThumbnailTest.vue';
import ThumbnailStudy from '../components/ThumbnailStudy.vue';
import TabNavigation from '@/components/UiUtils/TabNavigation/TabNavigation.vue';

const debounce = function (func, timeout = 500) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export default {
  name: "gallery",
  components: { Block, Loading, ThumbnailTest, ThumbnailStudy, TabNavigation},
  data() {
    return {
      searchTerm: "",
      projectId: "",
      project: null,
      artList: {
        content:[]
      },
      currentPage: 0,
      pages: [],
      artsIsLoading: false,
      tokensTests: [],
      studiesList: [],
      collectionsIDs:"00,10,20,30,40,50,60,70,80,90,10,A0,B0,C0,D0,E0,F0",
      
      tabs:['editions', 'studies'],
      tabSelected: 'editions',

      isSortDropdownOpen: false,
      isOrderedFirsToLast: false,
      isOrderedLastToFirst: false,
      sortFilterLabel: 'arts (first to last)',
      imagesUrl: process.env.VUE_APP_IMAGES_URL
    };
  },
  async mounted() {
    await this.getProject();
    await this.getArts(this.currentPage);
    /* await this.getTestTokens(); */
    await this.getAllStudies();
    this.isOrderedFirsToLast = true;
  },
  computed: {
    noTokens() {
      return !this.artsIsLoading && this.artList.content.length === 0;
    },
    firstPageElement() {
        return (this.currentPage * this.artList.size) + 1
    },
    lastPageElement() {
        return ( this.firstPageElement + this.artList.numberOfElements) - 1
    },
    projectAuthors(){
        return this.project.authorWebsite.split(",").map((res) => {
            return {
                website: res,
                username: "@" + res.slice(20),
            }
        });
    }
  },
  filters: {
        collectionName: function (value) {
            if (!value) return ''
            const x = value.indexOf("[");
            value = value.substring(0,x)
            return value.replaceAll("'",'"')
        }
  },
  watch: {
      artList() {
          this.showPages(this.currentPage, this.artList.totalPages);
      }
  },
  methods: {
    async getProject() {
      this.projectId = this.$route.params.projectId;
      this.project = await api.projects.get(this.$route.params.projectId);
    },
    async getArts(page) {  
      this.artsIsLoading = true;
      let tokenSearching = this.searchTerm
      
      const response  = await api.projects.arts.search( this.projectId,tokenSearching, 64, page);

      if(response.content.length > 0 ) {
        this.artList = response;
        if(this.isOrderedFirsToLast) {
            this.artList.content.sort((a, b) => (a.id > b.id) ? 1 : -1);
        }else if (this.isOrderedLastToFirst) {
            this.artList.content.sort((a, b) => (a.id < b.id) ? 1 : -1);
        }
        this.currentPage = page;
        this.$router.push({
            name: 'gallery',  
            query: {
                pageSize: response.pageable.pageSize, 
                offset: response.pageable.offset 
            }
        });
      }

      /* this.showPages(page, response.totalPages);*/
      setTimeout(() => this.artsIsLoading = false, 500);
    },
    showPages(currentPage, totalPages){
        for (let i = currentPage; i < totalPages; i++) {
            this.pages.push(i);
        }
    },
    nextPage () { 
        this.getArts(this.currentPage + 1 );
        window.scrollTo({ top: 0 }); 
    },
    previousPage () { 
        this.getArts(this.currentPage - 1 );
        window.scrollTo({ top: 0 });  
    },
    goToFirstPage() { 
        this.getArts(this.currentPage = 0 );
        window.scrollTo({ top: 0 });  
    },
    goToLastPage() { 
        this.getArts(this.currentPage = this.artList.totalPages - 1 );
        window.scrollTo({ top: 0 });  
    },
    searchArt: debounce(async function (event) {
      if (event.keyCode == 13) {
        event.target.blur();
      }
      await this.getArts();
    }, 250),
    async getTestTokens() {
        try {
            const res = await api.projects.tests.getAll(this.projectId, 32);
            this.tokensTests = res.content;
        } catch (error) {
            console.error(error);
        } 
    },
    async getAllStudies() {
        try {
            const res = await api.projects.studies.getStudiesByIds(this.collectionsIDs);
            this.studiesList = res.content;
        } catch (error){
            console.error(error);
        } 
    },
    selectTab(tabSelected) {
        if(tabSelected == 'editions') {
            this.tabSelected = 'editions';
            this.getArts();
        } else {
            this.tabSelected = 'studies';
            this.getAllStudies();
        }
    },
    sortArtsFirstToLast() {
        if(this.isOrderedFirsToLast) return;

        this.isOrderedFirsToLast = true;
        this.isOrderedLastToFirst = false;
        this.getArts(this.currentPage);
        this.isSortDropdownOpen = false;
        this.sortFilterLabel = "arts (first to last)";
    },
    sortArtsLastToFirst() {
        if(this.isOrderedLastToFirst) return;

        this.isOrderedLastToFirst = true;
        this.isOrderedFirsToLast = false;
        this.getArts(this.currentPage);
        this.isSortDropdownOpen = false;
        this.sortFilterLabel = "arts (last to first)";
    },
    showHideSortDropdown() {
        this.isSortDropdownOpen = !this.isSortDropdownOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  padding-top: 10px;
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 16px;
  .project__info {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    a {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: var(--color-emerald);
      text-decoration: none;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
        :nth-child(2){
            align-self: flex-end;
        }
        .title {
            h1 {
                font-size: 38px;
                font-weight: 700;
                line-height: 46px;
                font-style: italic;
                margin-bottom: 8px;
                letter-spacing: 1px;
            }
            p {
                margin: 0px;
                font-weight: 700;
            }
            .author {
                display: flex;
                gap: 8px;
                align-items: center;
                a {
                    color: var(--color-emerald);
                    font-weight: 400;
                    text-decoration: none;
                }
                .social-network {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 4px;
                    flex-wrap: wrap;
                }
            }
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .section__title {
            h3 {
                margin: 0px;
            }
        }
        .filters {
            position: relative;
            .sort {
                display: flex;
                column-gap: 42px;
                .sort_by {
                    display: block;
                    margin: auto 0;
                }
                .sort__dropdown {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    button {
                        all: unset;
                        cursor: pointer;
                    }
                    &__options {
                        background-color: var(--color-neutral-black);
                        width: 176px;
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: 46px;
                        span {
                            cursor: pointer;
                            padding: 8px;
                            color: var(--color-neutral-white);
                            line-height: 24px;
                            &:hover {
                                background-color: var(--color-emerald);
                                transition: 0.2s;
                            }
                            &.selected {
                                background-color: var(--color-emerald);
                            }
                        }
                    }
                }
            }
            input {
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 4px;
                background-image: url(../assets/icons/search.svg);
                background-repeat: no-repeat;
                background-position: 16px 11px;
                text-indent: 36px;
                width: 184px;
                height: 46px;
                padding: 4px 16px;
                color: #808080;
                margin: 0px;
            }
        }
    }
  }
  .tab__desktop {
      display: none;
  }
  .loading-wrapper {
      height: 378px;
  }
  .arts {
    padding: 16px 0px;
    display: grid;
    gap: 32px 16px;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
      .card {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        &-image {
            img {
                width: 100%;
                height: auto;
            }
        }
        &-footer {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          background: transparent;
          span {
            line-height: 24px;
          }
          .text {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            a {
              color: var(--color-emerald);
              text-decoration: none;
              text-transform: lowercase;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-info {
        text-align: center;
    }
    &-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            &.primary {
                background-color: transparent;
                cursor: pointer;
                padding: 16px;
                margin: 0px;
                width: 40px;
                height: 40px;
                padding: 8px;
                font-size: 20px;
                font-weight: 700;
                &:disabled {
                    cursor: unset;
                }
            }
            &.page {
                cursor: pointer;
                margin: 0px;
                background-color: transparent;
                color: var(--color-neutral-black);
                padding: 10px;
                line-height: 24px;
                width: 44px;
                &:disabled {
                    cursor: var(--color-neutral-02);
                    opacity: 0.2;
                }
                &.active {
                  background-color: var(--color-neutral-black);
                  color: var(--color-neutral-white);
                }
            }
        }
    }
  }
  .studies {
    &__grid {
        padding-top: 16px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 32px;
    }
  }
  @include respond-to("xsmall and down") {
    margin: 0px;
    padding: 0px;
    .project__info {
      padding: 0px;
      row-gap: 16px;
      &__header {
        flex-direction: column;
        padding-bottom: 0px;
        align-self: unset;
        :nth-child(2){
            align-self: unset;
        }
        .title {
            .author {
                display: flex;
                flex-direction: column;
                align-items: unset;
            }
        }
        .navigation__desktop {
            display: none;
        }
      }
      &__actions {
            display: initial;
        .section__title {
            display: none;
        }
        .filters {
            .sort {
                .sort_by {
                    display: none;
                }
            }
            input {
                height: 46px;
                width: 100%;
                background-position: 15px 10px;
                text-indent: 33px;
            }
        }
      }
    }
    .tab__desktop {
        display: initial;
    }
    .arts {
      padding: 0px;
      grid-row-gap: 32px;
      grid-template-columns: repeat(1, 1fr);
    }
    .pagination {
        flex-direction: column;
        flex-direction: column-reverse;
        row-gap: 16px;
        margin: 24px 0px 20px 0px;
    }
    .studies {
        &__grid {
            padding-top: 0px;
            display: grid;
            grid-template-columns: unset;
            grid-row-gap: 16px;
        }
    }
  }
}
</style>
